var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar',{staticClass:"mb-8 primary fadeIn",attrs:{"flat":""}},[_c('v-app-bar-nav-icon',{staticClass:"d-inline d-flex d-lg-none",attrs:{"color":"white"},on:{"click":function($event){$event.stopPropagation();return _vm.showMobileDrawer.apply(null, arguments)}}}),_c('div',{staticClass:"welcome pt-4 d-inline"},[_c('h3',{staticClass:"d-none d-sm-flex mb-3 white--text"},[_vm._v("Hallo "+_vm._s(_vm.user.firstname)+"!")])]),_c('v-spacer'),_c('div',{staticClass:"d-inline-flex",attrs:{"id":"toolbar"}},[[_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":300,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","h":""}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"small":"","bordered":"","bottom":"","color":"primary","dot":"","offset-x":"10","offset-y":"10"}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-bell ")])],1)],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Neuigkeiten")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.$router.push('/notifications');_vm.menu = false}}},[_c('v-icon',[_vm._v("mdi-post")])],1)],1)],1)],1),_c('v-divider'),(_vm.notifications.length > 0)?_c('v-list',_vm._l((_vm.notifications),function(notification){return _c('a',{key:notification.id},[_c('v-list-item',{on:{"click":function($event){_vm.$router.push('/notifications/notification/'+notification.id);_vm.menu = false}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(notification.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(notification.title))]),_c('v-list-item-action',[(notification.link)?_c('v-icon',[_vm._v("mdi-link")]):_vm._e()],1),_c('v-list-item-action-text',[_vm._v(" "+_vm._s(_vm.getStringDate(notification.created))+" ")])],1)],1)}),0):_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_vm._v("Keine neuen Benachrichtigungen")])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.$router.push('/notifications');_vm.menu = false}}},[_vm._v(" Postfach ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Schließen ")])],1)],1)],1)],1)],_c('v-tooltip',{attrs:{"bottom":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.goTo('/Settings')}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-cog ")])],1)],1)]}}])},[_vm._v(" Einstellungen ")]),[_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":300,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","h":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi mdi-account-question ")])],1)]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-card',[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Du benötigst Hilfe?")])],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi mdi-account-question")])],1)],1)],1),_c('v-divider'),_c('v-list',[_c('a',[_c('v-list-item',{on:{"click":function($event){_vm.$router.push('/planner/Dashboard?newAccount=true');_vm.menu2 = false}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi mdi-rocket-launch-outline")])],1),_c('v-list-item-title',[_vm._v("Einführungstour")]),_c('v-list-item-action'),_c('v-list-item-action-text',[_vm._v(" Starten ")])],1)],1)]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.$router.push('/support');_vm.menu2 = false}}},[_vm._v("Problem melden / Support")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.$router.push('/feedback');_vm.menu2 = false}}},[_vm._v("Verbesserung vorschlagen")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.menu2 = false}}},[_vm._v(" Schließen ")])],1)],1)],1)],1)],_c('v-tooltip',{attrs:{"bottom":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.logout()}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-door-closed ")])],1)],1)]}}])},[_vm._v(" Ausloggen ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }