<template>
  <v-toolbar class=" mb-8 primary fadeIn" flat>

    <v-app-bar-nav-icon
        @click.stop="showMobileDrawer"
        color="white"
        class="d-inline d-flex d-lg-none "
    >
    </v-app-bar-nav-icon>

    <div class="welcome pt-4 d-inline">
      <h3 class="d-none d-sm-flex mb-3 white--text">Hallo {{ user.firstname }}!</h3>
    </div>

    <v-spacer></v-spacer>

    <div class="d-inline-flex" id="toolbar">


      <template>
        <div class="text-center">
          <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-width="300"
              offset-y

          >
            <template v-slot:activator="{ on, attrs }">

              <v-btn icon v-bind="attrs"
                     v-on="on" h>
                <v-badge small bordered bottom color="primary" dot offset-x="10" offset-y="10">

                  <v-icon color="white">
                    mdi-bell
                  </v-icon>
                </v-badge>
              </v-btn>
            </template>

            <v-card>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Neuigkeiten</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon @click="$router.push('/notifications');menu = false">
                      <v-icon>mdi-post</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>

              <v-list v-if="notifications.length > 0">
                <a v-for="notification of notifications" :key="notification.id">
                  <v-list-item @click="$router.push('/notifications/notification/'+notification.id);menu = false">
                    <v-list-item-action>
                      <v-icon>{{ notification.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ notification.title }}</v-list-item-title>
                    <v-list-item-action>
                      <v-icon v-if="notification.link">mdi-link</v-icon>
                    </v-list-item-action>
                    <v-list-item-action-text>
                      {{ getStringDate(notification.created) }}
                    </v-list-item-action-text>
                  </v-list-item>
                </a>
              </v-list>
              <v-list v-else>
                <v-list-item>
                  <v-list-item-title>Keine neuen Benachrichtigungen</v-list-item-title>
                </v-list-item>
              </v-list>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="$router.push('/notifications');menu = false"
                >
                  Postfach
                </v-btn>
                <v-btn
                    text
                    @click="menu = false"
                >
                  Schließen
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </div>
      </template>


      <v-tooltip bottom color="secondary">
        <template v-slot:activator="{ on, attrs }">
        <span
            v-bind="attrs"
            v-on="on"
        >
          <v-btn icon @click="goTo('/Settings')">
        <v-icon color="white">
          mdi-cog
        </v-icon>
      </v-btn>
        </span>
        </template>
        Einstellungen
      </v-tooltip>


      <template>
        <div class="text-center">
          <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-width="300"
              offset-y

          >
            <template v-slot:activator="{ on, attrs }">

              <v-btn icon v-bind="attrs"
                     v-on="on" h>
                  <v-icon color="white">
                    mdi mdi-account-question
                  </v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Du benötigst Hilfe?</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon>mdi mdi-account-question</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>

              <v-list>
                <a>
                  <v-list-item @click="$router.push('/planner/Dashboard?newAccount=true');menu2 = false">
                    <v-list-item-action>
                      <v-icon>mdi mdi-rocket-launch-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>Einführungstour</v-list-item-title>
                    <v-list-item-action>
                    </v-list-item-action>
                    <v-list-item-action-text>
                      Starten
                    </v-list-item-action-text>
                  </v-list-item>

                </a>
              </v-list>

              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    @click="$router.push('/support');menu2 = false"
                    text>Problem melden / Support</v-btn>

              </v-card-actions>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    @click="$router.push('/feedback');menu2 = false"
                    text>Verbesserung vorschlagen</v-btn>

              </v-card-actions>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="menu2 = false"
                >
                  Schließen
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </div>
      </template>


      <v-tooltip bottom color="secondary">
        <template v-slot:activator="{ on, attrs }">
        <span
            v-bind="attrs"
            v-on="on"
        >
           <v-btn icon @click="logout()">
      <v-icon color="white">
        mdi-door-closed
      </v-icon>
    </v-btn>
        </span>
        </template>
        Ausloggen
      </v-tooltip>
    </div>
  </v-toolbar>
</template>

<script>
import {mapGetters} from "vuex";
import {date2String, error} from "@/helper/helper";

export default {
  name: "Toolbar",
  computed: {
    ...mapGetters('auth', {
      user: 'user',
    }),
    ...mapGetters('notification', {
      notifications: 'notificationsUnRead',
    }),
  },
  mounted() {
    this.$store.dispatch('notification/getNotifications', {
      uid: this.user.id,
      onlyUnread: true
    }).catch((err) => {
      this.message = error(err)
      setTimeout(() => this.message.show = false, 5000);
    })
  },
  data() {
    return {
      menu: false,
      menu2: false,
      newMessages: [{
        icon: "mdi mdi-rocket-launch-outline",
        title: "Neue Version 0.1.2",
        link: "https://wedding-flow.de/"
      }]
    }
  },
  methods: {
    showMobileDrawer() {
      this.$emit('showMobileDrawer')
    },
    goTo(route) {
      this.$router.push(route)
    },
    logout() {
      this.$store.dispatch('auth/signedOut', {}).then(() => {
        console.log('User ausgeloggt!')
      }).catch((e) => {
        console.log(e)
        console.log('User konnte nicht ausgeloggt werden!')
      })
    },
    getStringDate(date) {
      return date2String(date)
    },
  }
}
</script>

<style scoped>
.welcome {
  padding-left: 275px;
}

@media only screen and (max-width: 1280px) {
  .welcome {
    padding-left: 10px
  }
}

</style>
